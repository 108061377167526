import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { FaArrowRight } from 'react-icons/fa'

// Images
import LOGO from "../assets/img/logo.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lg: i18next.language,
        }
    }

    componentDidMount = async () => {
        if (localStorage.getItem("i18nextLng"))
            this.switchLanguage(localStorage.getItem("i18nextLng"))
    }

    switchLanguage = (lg) => {
        i18next.changeLanguage(lg)
        this.setState({ lg })
        localStorage.setItem("i18nextLng", lg)
    }

    render() {
        return (
            <header>
                <div className="left_board">
                    <img src={LOGO} alt={'Logo CodInsight'} className="logo" onClick={() => window.location.href = '/'} />
                    <span className="logo_text">Codinsight</span>
                </div>
                <div className="right_board">
                    <a href={"/#expertise"} className="entry">
                        {this.props.t('expertise')}
                    </a>
                    <a href={"/#services"} className="entry">
                        {this.props.t('services')}
                    </a>
                    <a href={"/#values"} className="entry">
                        {this.props.t('values')}
                    </a>
                    <a href={"/#portfolio"} className="entry">
                        {this.props.t('portfolio')}
                    </a>
                    <div className="button" onClick={() => window.location.href="/#contact"}>
                        {this.props.t('contact_us')} <FaArrowRight />
                    </div>
                    {this.state.lg === "fr" ?
                        <div className="entry" onClick={() => this.switchLanguage('en')}>
                            EN
                        </div>
                        :
                        <div className="entry" onClick={() => this.switchLanguage('fr')}>
                            FR
                        </div>
                    }
                </div>
            </header>
        );
    }
}

export default withTranslation()(Header);