import api from './api';

class SubscriptionService {
      async sendMsg(email, message, hcaptcha_passcode) {
        return await api
          .post('/business/contact', {
            website: "https://codinsight.com",
            email,
            message,
            hcaptcha_passcode,
          })
          .then(async (response) => {
            return response.data;
          });
      }
}

export default new SubscriptionService();
