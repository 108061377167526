import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Images
import LOGO from "../assets/img/logo.png";
import { FaArrowRight } from 'react-icons/fa';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="center">
                <footer className="blackboard">
                    <div className="third">
                        <img src={LOGO} alt={'Logo CodInsight'} className="logo" onClick={() => window.location.href = '/'} />
                        <span className="logo_text white">Codinsight</span>
                        <div className="button" onClick={() => window.location.href = "/privacy"}>
                            {this.props.t('privacy_policy')} <FaArrowRight />
                        </div>
                    </div>
                    <div className="third">
                        <p>
                            <small>
                                <strong>{this.props.t('site_editor')}</strong><br />
                                {this.props.t('editor_desc')}<br />
                                {this.props.t('vat')}
                            </small>
                        </p>
                        <p>
                            <small>
                                Email : contact@codinsight.com
                                <br/>
                                {this.props.t('publishing_dir')}
                            </small>
                        </p>
                    </div>
                    <div className="third">
                        <p>
                            <small>
                                <strong>{this.props.t('host')}</strong><br />
                                {this.props.t('hosting')}<br />
                                Amazon Web Services LLC<br />
                                P.O. Box 81226<br />
                                Seattle, WA 98108-1226
                            </small>
                        </p>
                    </div>
                    <p>{this.props.t('property')} Copyright <strong>@Codinsight</strong>. 2021-2024</p>
                </footer>
            </div>
        );
    }
}

export default withTranslation()(Footer);