import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

class Cookies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
        }
    }

    componentDidMount = () => {
        if (window.localStorage.getItem('display_cookie')) {
            this.setState({display: false})
        }
    }

    render() {
        return (
            this.state.display && <div className="cookies">
                <div className="close" onClick={() => {this.setState({ display: false }); window.localStorage.setItem('display_cookie', "hidden");}}><FaTimes /></div>
                <h3>
                    Cookies
                </h3>
                <p>
                    {this.props.t('cookies_txt')}
                </p>
            </div>
        );
    }
}

export default withTranslation()(Cookies);