import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../layout/external_header';
import Footer from '../../layout/footer';

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header scrollTo={this.scrollTo} />
                <div className="privacy">
                    <h1>{this.props.t('privacy_policy')}</h1>
                    <p><small>{this.props.t('revision_date')}</small></p>
                    <p>
                        {this.props.t('privacy01')}
                    </p>

                    <h2>{this.props.t('privacy02')}</h2>
                    <p>
                        {this.props.t('privacy03')}
                    </p>

                    <h2>{this.props.t('privacy04')}</h2>
                    <p>
                        {this.props.t('privacy05')}
                    </p>
                    <p>
                        <span className="ml-20">{this.props.t('privacy06')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy07')}</span>
                    </p>
                    <h2>{this.props.t('privacy08')}</h2>
                    <p>
                        {this.props.t('privacy09')}
                    </p>
                    <p>
                        {this.props.t('privacy10')}
                    </p>
                    <p>
                        {this.props.t('privacy11')}
                    </p>
                    <p>
                        {this.props.t('privacy12')}
                    </p>
                    <p>
                        {this.props.t('privacy13')}
                    </p>
                    <p>
                        {this.props.t('privacy14')}
                    </p>
                    <p>
                        {this.props.t('privacy15')}
                    </p>
                    <h2>{this.props.t('privacy16')}</h2>
                    <p>
                        {this.props.t('privacy17')}
                    </p>
                    <h2>{this.props.t('privacy18')}</h2>
                    <p>
                        {this.props.t('privacy19')}
                    </p>
                    <h2>{this.props.t('privacy20')}</h2>
                    <p>
                        {this.props.t('privacy21')}
                    </p>
                    <p>
                        {this.props.t('privacy22')}
                    </p>
                    <h2>{this.props.t('privacy23')}</h2>
                    <p>{this.props.t('privacy24')}</p>
                    <p>
                        <span className="ml-20">{this.props.t('privacy25')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy26')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy27')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy28')}</span>
                    </p>
                    <p>
                        {this.props.t('privacy29')}
                    </p>
                    <h2>{this.props.t('privacy30')}</h2>
                    <p>
                        {this.props.t('privacy31')}
                    </p>
                    <p>
                        {this.props.t('privacy32')}
                    </p>
                    <p>
                        <span className="ml-20">{this.props.t('privacy33')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy34')}</span>
                        <br />
                        <span className="ml-20">{this.props.t('privacy35')}</span>
                    </p>
                    <p>
                        {this.props.t('privacy36')}
                    </p>
                    <h2>{this.props.t('privacy37')}</h2>
                    <p>{this.props.t('privacy38')}</p>
                    <p>{this.props.t('privacy39')}</p>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Privacy);