import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import { FaArrowRight, FaCalendarAlt } from 'react-icons/fa';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { SITE_KEY } from '../../constants';
import SubscriptionService from "../../services/subscription_services";
import { MutatingDots } from "react-loader-spinner";
import Cookies from '../../layout/cookies';

// Media
import PORTRAIT from '../../assets/img/portrait.jpg'
import WINGS from '../../assets/img/wings.png'
import BUSTE from '../../assets/img/buste.png'
import DIGITAL_NOMAD from '../../assets/img/digital_nomad.jpg'
import STR1 from '../../assets/img/str1.png'
import STR2 from '../../assets/img/str2.png'
import STR3 from '../../assets/img/str3.png'
import MODERNMAN from '../../assets/img/modern_man.png'
import FLY1 from '../../assets/img/arrows_white1.png'
import FLY2 from '../../assets/img/arrows_white2.png'
import FLY3 from '../../assets/img/arrows_white3.png'
import LOYALBUDSVISUAL from '../../assets/img/lb_mckup.png'
import VIGICARDVISUAL from '../../assets/img/vigi_mckup.png'
import TEZORUSVISUAL from '../../assets/img/tezorus.png'
import CLEANIZIVISUAL from '../../assets/img/cleanizi_safety.png'
import LOYALBUDSLOGO from '../../assets/img/lb_logo_white.png'
import VIGICARDLOGO from '../../assets/img/vigi_white.png'
import TEZORUSLOGO from '../../assets/img/tezorus_logo.png'
import CLEANIZILOGO from '../../assets/img/cleanizi_safety_white.png'
import NEWSPAPERBOY from '../../assets/img/newspaper_reader.png'

// News
import JDE from "../../assets/img/jde.png"
import ZDNET from "../../assets/img/zdnet.jpeg"
import FINYEAR from "../../assets/img/finyear.jpg"
import XTZNEWS from '../../assets/img/xtz_news.jpg'
import LETTREM from "../../assets/img/M.png"
import LATRIBUNE from "../../assets/img/latribune.jpeg"
import FRANCEBLEUE from "../../assets/img/france_bleu.png"

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            email: null,
            message: null,
            success: false,
            error: null,
            info: null,
            loading: false,
        }
    }

    componentDidMount = () => {
        const currentUrl = window.location.href
        if (currentUrl.indexOf('#') > -1) {
            const anchor = currentUrl.split('#')[1]
            this.scrollTo(anchor)
        }
    }

    scrollTo = (anchor) => {
        let element = document.getElementById(anchor);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    onVerifyCaptcha = (token) => {
        this.setState({ token })
    }

    subMsg = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email)) {
            this.setState({ error: this.props.t('email_format'), info: null, success: false })
        } else if (!this.state.message) {
            this.setState({ error: this.props.t('missing_msg'), info: null, success: false })
        } else if (!this.state.token) {
            this.setState({ error: this.props.t('missing_captcha'), info: null, success: false })
        } else {
            this.setState({ error: null, success: false, loading: true });
            const resp = await SubscriptionService.sendMsg(this.state.email, this.state.message, this.state.token)
            this.setState({ success: resp.status, info: null, error: !resp.status && resp.message, loading: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header scrollTo={this.scrollTo} />
                <div className="spliter_one">
                    <p>
                        <strong>Codinsight</strong> {this.props.t('intro_codinsight')}
                        <a href={"#expertise_"} onClick={() => this.scrollTo("expertise")}>{this.props.t('learn_more')}</a>
                    </p>
                </div>
                <div className="spliter_two">
                    <h1>{this.props.t('first_title')}<span className="primary">.</span></h1>
                </div>
                <div className="blackboard">
                    <div className="spliter_fifty">
                        <img src={WINGS} alt="Portrait" className="wings" />
                        <div className="blurb">
                            <h2>{this.props.t('second_title')}</h2>
                            <p>
                                {this.props.t('experts')}
                            </p>
                        </div>

                    </div>
                    <div className="spliter_fifty">
                        <img src={PORTRAIT} alt="Portrait" className="portrait" />
                    </div>
                </div>
                <div className="center" id="expertise">
                    <div className="third">
                        <h3>{this.props.t('expertise_title')}</h3>
                        <p>
                            {this.props.t('work_process')}
                        </p>
                        <p>
                            {this.props.t('data_security')}

                        </p>
                        <div className="button" onClick={() => this.scrollTo("portfolio")}>
                            {this.props.t('our_work')} <FaArrowRight />
                        </div>
                    </div>
                    <div className="third">
                        <img src={BUSTE} alt="Buste" className="buste" />
                    </div>
                    <div className="third">
                        <h3>{this.props.t('build_together')}</h3>
                        <p>
                            {this.props.t('tailored_solutions')}
                        </p>
                        <div className="button" onClick={() => this.scrollTo("contact")}>
                            {this.props.t('contact_us')} <FaArrowRight />
                        </div>
                        <p>
                            {this.props.t('visio')}
                        </p>
                        <div className="button" onClick={() => window.open("https://calendly.com/codinsight")}>
                            {this.props.t('make_rdv')} <FaCalendarAlt />
                        </div>
                    </div>
                    <div className="blackboard" id="services">
                        <h1>{this.props.t('our_services')}</h1>
                        <div className="center">
                            <div className="third">
                                <div className="board">
                                    <img src={STR1} className="origami" alt="Origami Codinsight orange" />
                                    <h2 className="white">{this.props.t('web3')}</h2>
                                    <p>
                                        {this.props.t('advice_strategy')}
                                        <br />
                                        {this.props.t('integration_wallet')}
                                    </p>
                                </div>
                            </div>
                            <div className="third">
                                <div className="board blackbckg">
                                    <img src={STR2} className="origami" alt="Origami Codinsight green" />
                                    <h2 className="white">{this.props.t('data_protection')}</h2>
                                    <p>
                                        {this.props.t('awareness')}
                                        <br />
                                        {this.props.t('audits')}
                                        <br />
                                        {this.props.t('rgpd_plan')}
                                    </p>
                                </div>
                            </div>
                            <div className="third">
                                <div className="board">
                                    <img src={STR3} className="origami" alt="Origami Codinsight blue" />
                                    <h2 className="white">{this.props.t('innovation_digitalisation')}</h2>
                                    <p>
                                        {this.props.t('analysis')}
                                        <br />
                                        {this.props.t('app_dev')}
                                        <br />
                                        {this.props.t('changes')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="center" id="values">
                            <h1>{this.props.t('our_values')}</h1>
                            <div className="third big_third">
                                <p>
                                    {this.props.t('digitalisation_experts')}
                                </p>
                                <p>
                                    {this.props.t('performances')}
                                </p>
                                <img src={MODERNMAN} className="modern" alt="Mashup portrait" />
                            </div>
                            <div className="third notop">
                                <div className="block">
                                    <img src={FLY1} className="fly" alt="fly" />
                                    <h2 className="white notop">{this.props.t('innovation')}</h2>
                                    <p>
                                        {this.props.t('ui_exp')}
                                        <br />
                                        {this.props.t('new_usages')}
                                        <br />
                                        {this.props.t('new_targets')}
                                        <br />
                                        {this.props.t('be_different')}
                                    </p>
                                </div>
                                <div className="block">
                                    <img src={FLY2} className="fly" alt="fly" />
                                    <h2 className="white notop">{this.props.t('efficiency')}</h2>
                                    <p>
                                        {this.props.t('automated')}
                                        <br />
                                        {this.props.t('optimize')}
                                        <br />
                                        {this.props.t('friction')}
                                        <br />
                                        {this.props.t('test_market')}
                                    </p>
                                </div>
                                <div className="block">
                                    <img src={FLY3} className="fly" alt="fly" />
                                    <h2 className="white notop">{this.props.t('serenity')}</h2>
                                    <p>
                                        {this.props.t('protect_data')}
                                        <br />
                                        {this.props.t('manage_infrastructure')}
                                        <br />
                                        {this.props.t('brand_image')}
                                        <br />
                                        {this.props.t('trust_client')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h1 id="portfolio">{this.props.t('portfolio')}</h1>
                        <p className="left">
                            {this.props.t('expertise_innovation')}
                        </p>
                        <div className="center">
                            <div className="folio_holder">
                                <img className="visual" src={VIGICARDVISUAL} alt="Vigicard mobile visual" />
                                <div className="announcer">
                                    <img src={VIGICARDLOGO} className="brand" alt="LoyaVigicardlBuds logo" />
                                    <p className="title">
                                        {this.props.t('vigicard1')}
                                    </p>
                                    <p className="description">
                                        {this.props.t('vigicard2')}
                                    </p>
                                    <div className="button" onClick={() => window.open("https://vigicard.fr")}>
                                        {this.props.t('know_more')} <FaArrowRight />
                                    </div>
                                </div>
                            </div>
                            <div className="folio_holder">
                                <img className="visual" src={LOYALBUDSVISUAL} alt="LoyalBuds mobile visual" />
                                <div className="announcer">
                                    <img src={LOYALBUDSLOGO} className="brand" alt="LoyalBuds logo" />
                                    <p className="title">
                                        {this.props.t('loyalbuds1')}
                                    </p>
                                    <p className="description">
                                        {this.props.t('loyalbuds2')}
                                    </p>
                                    <div className="button" onClick={() => window.open("https://loyalbuds.io")}>
                                        {this.props.t('know_more')} <FaArrowRight />
                                    </div>
                                </div>
                            </div>
                            <div className="folio_holder">
                                <img className="visual" src={CLEANIZIVISUAL} alt="Cleanizi mobile visual" />
                                <div className="announcer">
                                    <img src={CLEANIZILOGO} className="brand" alt="Cleanizi logo" />
                                    <p className="title">
                                        {this.props.t('cleanizi1')}
                                    </p>
                                    <p className="description">
                                        {this.props.t('cleanizi2')}
                                    </p>
                                    <div className="button" onClick={() => window.open("https://cleanizi.com")}>
                                        {this.props.t('know_more')} <FaArrowRight />
                                    </div>
                                </div>
                            </div>
                            <div className="folio_holder">
                                <img className="visual" src={TEZORUSVISUAL} alt="Tezorus mobile visual" />
                                <div className="announcer">
                                    <img src={TEZORUSLOGO} className="brand" alt="Tezorus logo" />
                                    <p className="title">
                                        {this.props.t('tezorus1')}
                                    </p>
                                    <p className="description">
                                        {this.props.t('tezorus2')}
                                    </p>
                                    <div className="button" onClick={() => window.open("https://tezorus.com")}>
                                        {this.props.t('know_more')} <FaArrowRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="news">
                        <h1 className="left">{this.props.t('speak_about_us')}</h1>
                        <div className="third">
                            <div className="item" style={{ backgroundImage: `url('${LETTREM}')` }} onClick={() => window.open("https://www.lalettrem.fr/la-lettre-m/fabien-bucamp")}></div>
                            <div className="item" style={{ backgroundImage: `url('${LATRIBUNE}')` }} onClick={() => window.open("https://www.latribune.fr/")}></div>
                            <div className="item" style={{ backgroundImage: `url('${FRANCEBLEUE}')` }} onClick={() => window.open("https://www.francebleu.fr/emissions/l-eco-d-ici/video-vigicard-une-nouvelle-application-pour-les-personnes-allergiques-a-certains-medicaments-8077940")}></div>
                            <div className="item" style={{ backgroundImage: `url('${JDE}')` }} onClick={() => window.open("https://www.lejournaldesentreprises.com/occitanie/breve/le-projet-montpellierain-vigicard-recoit-le-soutien-de-la-fondation-tezos-2075183")}></div>
                        </div>
                        <div className="third notop">
                            <img src={NEWSPAPERBOY} alt="Boy reading news" className="buste" />
                        </div>
                        <div className="third">
                            <div className="item" style={{ backgroundImage: `url('${ZDNET}')` }} onClick={() => window.open("https://www.zdnet.fr/actualites/le-projet-vigicard-trace-les-allergies-medicamenteuses-par-la-blockchain-39962594.htm")}></div>
                            <div className="item" style={{ backgroundImage: `url('${FINYEAR}')` }} onClick={() => window.open("https://www.finyear.com/Blockchain-Tezos-un-projet-healthtech-pour-tracer-les-allergies-medicamenteuses_a50108.html")}></div>
                            <div className="item" style={{ backgroundImage: `url('${XTZNEWS}')` }} onClick={() => window.open("https://xtz.news/fr/non-classifiee/projet-vigicard-lappli-pour-suivre-les-allergies-medicamenteuses-sur-tezos/")}></div>
                        </div>
                    </div>
                    <div className="news" id="contact">
                        <h1 className="left">{this.props.t('contact_us')}</h1>
                        <div className="third notop">
                            <img src={DIGITAL_NOMAD} className="nomad" alt="Digital nomad" />
                        </div>
                        <div className="third big_third notop">
                            <p><small>{this.props.t('fill_required')}</small></p>
                            <textarea onChange={(e) => this.setState({ message: e.target.value })} placeholder={this.props.t('your_msg')}></textarea>
                            <input onChange={this.handleEmail} type="email" placeholder={this.props.t('email_contact')} />
                            <div className="hcaptcha">
                                <HCaptcha
                                    sitekey={SITE_KEY}
                                    onVerify={this.onVerifyCaptcha}
                                />
                            </div>
                            <p className="smaller">
                                {this.props.t('rgpd01')}
                                <br />
                                {this.props.t('rgpd02')} <strong className="pointer" onClick={() => window.open("mailto:contact@codinsight.com")}>contact@codinsight.com</strong>
                                <br />
                                {this.props.t('rgpd03')} <strong className="pointer" onClick={() => window.location.href("/privacy")}>{this.props.t('privacy_policy')}</strong>.
                            </p>
                            {this.state.error && <div className="badge_danger">{this.state.error}</div>}
                            {this.state.loading ?
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#000"
                                    secondaryColor='#000'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{ display: "inline-block", marginTop: "-15px" }}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                this.state.success ?
                                    <div className="badge_success">
                                        {this.props.t('success')}
                                    </div>
                                    :
                                    <div className="button" onClick={this.subMsg}>
                                        {this.props.t('send_msg')} <FaArrowRight />
                                    </div>
                            }
                            <br />
                            <hr />
                            <p>
                                <strong>Codinsight SAS</strong><br />
                                Centre d'affaires Le Symbiose<br />
                                65 rue de la Garriguette<br />
                                34130 Saint-Aunès | France
                            </p>
                            <p>
                                <a href={"mailto:contact@codinsight.com"}>contact@codinsight.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
                <Cookies/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Home);